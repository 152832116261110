import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Button from '../uiComponents/button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${mobileVW(60)};
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-flow: row-reverse;
    padding-bottom: 60px;
  }
`

const TextWrapper = styled.div`
  font-family: ${font.ben};
  background-color: ${props => props.background};
  color: ${props => props.font};
  padding: ${mobileVW(60)} ${mobileVW(32)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    padding: 77px 205px 60px;
    justify-content: center;
  }
  h2 {
    text-align: center;
    font-size: ${mobileVW(36)};
    line-height: ${mobileVW(39)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 48px;
      line-height: 52px;
    }
  }
`
const ImageWrapper = styled.div`
  height: ${mobileVW(320)};
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (min-width: ${desktopBreakpoint}) {
    min-width: 40%;
    height: 461px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: ${mobileVW(35)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 44px;
  ]
`

import { mobileVW, colors, desktopBreakpoint, font } from '../../../styles'

const BannerSection = ({ content }) => {
  const {
    image,
    text,
    article,
    buttonText,
    backgroundColor,
    fontColor,
  } = content

  return (
    <>
      <Wrapper>
        {image && (
          <ImageWrapper>
            <Image fluid={image.fluid} />
          </ImageWrapper>
        )}
        <TextWrapper background={backgroundColor} font={fontColor}>
          <h2>{text}</h2>
          <ButtonWrapper>
            <Button
              slug={article.slug}
              ctaType={'secondary'}
              text={buttonText}
            />
          </ButtonWrapper>
        </TextWrapper>
      </Wrapper>
    </>
  )
}

export default BannerSection
