import React from 'react'
import RichText from '../shared/RichText'
import styled from 'styled-components'
import { font, colors, mobileVW, desktopBreakpoint } from '../../styles'

const Container = styled.section`
  padding: 70px 20px 0;
  max-width: 934px;
  margin: 0 auto;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 70px 0 0;
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(34)};
  text-align: center;
  margin: 0 auto ${mobileVW(50)};
  color: ${colors.brownDarkest};
  text-transform: lowercase;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 46px;
    margin: 0 auto 62px;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${mobileVW(13)};

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 66px;
  }
`

const ListItem = styled.li`
  color: ${colors.brownDarkest};
`

const Question = styled.h4`
  margin: 0;
  font-size: ${mobileVW(22)};
  line-height: ${mobileVW(20)};
  margin-bottom: ${mobileVW(16)};
  font-family: ${font.ben};
  text-transform: lowercase;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 22px;
    line-height: 20px;
    margin-bottom: 11px;
  }
`

const Answer = styled.div`
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(17)};
  font-family: ${font.larsseit};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 17px;
  }
`

const FAQ = ({ content }) => {
  const { title, faqs = [] } = content

  if (faqs.length == 0) return null

  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {faqs.map(({ question, answer }, i) => (
          <ListItem key={i}>
            <Question>{question}</Question>
            <Answer>
              <RichText json={answer.json} />
            </Answer>
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default FAQ
