import React from 'react'
import styled from 'styled-components'
import { Widget, PopupButton } from '@typeform/embed-react'
import { font } from '../../../styles'

export default function Quizz({ content }) {
  const height = content.height ? content.height : '374px'

  return (
    <Wrapper>
      {content.popup ? (
        <div className='typeform_popup' style={{ height: height }}>
          <h2>{content.quizzTitle}</h2>
          <p>{content.quizzSubtitle}</p>
          <PopupButton id={content.quizzId}>{content.buttonText}</PopupButton>
        </div>
      ) : (
        <Widget
          id={content.quizzId}
          className='typeform_widget'
          style={{ height: height }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;

  .typeform_widget {
    width: 100%;
  }

  .typeform_popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee3da;
    h2 {
      font-family: ${font.ben};
      font-size: 30px;
    }
    p {
      font-family: ${font.larsseit};
      font-size: 16px;
      margin-top: 13px;
      color: #65473f;
    }
    button {
      font-family: ${font.larsseit};
      font-size: 18px;
      background-color: #cd8f6e;
      padding: 10px 17px;
      border-radius: 6px;
      margin-top: 21px;
    }
  }
`