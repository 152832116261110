import React from 'react'
import CategorySwiper from '../components/shared/modules/categorySwiper'
import GridSection from '../components/shared/modules/gridSection'
import CircleSwiper from '../components/shared/modules/circleSwiper'
import StandardSwiper from '../components/shared/modules/standardSwiper'
import BulletsSwiper from '../components/shared/modules/bulletsSwiper'
import SectionBlock from '../components/shared/modules/sectionBlock'
import NewsletterBlock from '../components/shared/modules/newsletterBlock'
import TwoQuotes from '../components/shared/modules/TwoQuotes.js'
import VideoWithTitleAndText from '../components/shared/modules/VideoWithTitleAndText.js'
// import TiledSection from '../components/shared/modules/tiledSection'
import BannerSection from '../components/shared/modules/bannerSection'
import ArticleProductSwiper from '../components/shared/swipers/articleProductSwiper'
import FAQ from '../components/product/FAQ'
import ImageAndTextSection from '../components/shared/modules/imageAndTextSection'
import MediaSwiper from '../components/shared/swipers/MediaSwiper'
import Quizz from '../components/shared/modules/quizz'

function useGetContentBlock({ block, type, key, makeHeaderMenu = false }) {
  switch (type) {
    case 'category swiper':
      return <CategorySwiper key={key} content={block} />
    case 'two quotes':
      return <TwoQuotes key={key} contentBlock={block} />
    case 'video with title and text':
      return (
        <VideoWithTitleAndText key={key} page='product' contentBlock={block} />
      )
    case 'circle swiper':
      return <CircleSwiper key={key} content={block} />
    case 'standard swiper':
      return <StandardSwiper key={key} content={block} />
    case 'bullets swiper':
      return <BulletsSwiper key={key} content={block} />
    case 'section':
      return <SectionBlock key={key} content={{ ...block, makeHeaderMenu }} />
    case 'faqs':
      return <FAQ key={key} content={block} />
    case 'email capture':
      return <NewsletterBlock key={key} content={block} />
    // case 'tiles':
    //   return <TiledSection key={key} content={block} />
    case 'grid':
      return <GridSection key={key} content={block} />
    case 'banner':
      return <BannerSection key={key} content={block} />
    case 'image and text section':
      return <ImageAndTextSection key={key} content={block} />
    case 'featured slider':
      return (
        <ArticleProductSwiper
          key={key}
          content={{ ...block, slideCategories: block.products }}
          mini
        />
      )
    case 'image slider':
      return <MediaSwiper key={key} content={block} />
    case 'quizz':
      return <Quizz key={key} content={block} />
    default:
      return null
  }
}

function useRenderContentBlocks(blocks) {
  return blocks.map(({ type, block, makeHeaderMenu }, i) =>
    useGetContentBlock({
      block,
      type: type[0],
      key: i,
      makeHeaderMenu,
    }),
  )
}

export default useRenderContentBlocks
