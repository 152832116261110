import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'

// Hooks
import useIsMobile from '../../../hooks/useIsMobile'

// Components
import Image from 'gatsby-image'
import BoxedContainer from '../boxedContainer'
import Button from '../uiComponents/button'

// install Swiper components
SwiperCore.use([Navigation])

const Wrapper = styled.div`
  text-align: center;

  padding: ${props =>
    props.blockPosition === 'top'
      ? `${mobileVW(48)} 0 0 0`
      : props.blockPosition === 'bottom'
      ? `0 0 ${mobileVW(48)} 0`
      : props.blockPosition === 'middle'
      ? `0`
      : `${mobileVW(48)} 0`}; // 'standalone'

  .swiper-container {
    padding-left: ${mobileVW(16)};
    margin-top: ${mobileVW(50)};
    @media (min-width: ${desktopBreakpoint}) {
      padding-left: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      @media (min-width: ${desktopBreakpoint}) {
        display: ${props => (props.showNavigation ? 'none' : 'flex')};
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${props =>
      props.blockPosition === 'top'
        ? `62px 0 0 0`
        : props.blockPosition === 'bottom'
        ? `0 0 64px 0`
        : props.blockPosition === 'middle'
        ? `0`
        : `62px 0 64px`}; // 'standalone'
    .swiper-container {
      margin: 80px 55px 0;
    }
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  text-align: center;
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  margin: 0 auto ${mobileVW(20)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    margin: 0 auto 24px;
    max-width: 565px;
    line-height: 1;
  }
`

const Subtitle = styled.h3`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  text-align: center;
  margin: auto;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    max-width: 650px;
  }
`

const SlideImage = styled.a`
  position: relative;

  svg {
    height: ${mobileVW(23)};
    @media (min-width: ${desktopBreakpoint}) {
      height: 23px;
    }
  }

  .gatsby-image-wrapper {
    height: ${mobileVW(270)};
    border-radius: ${mobileVW(6)};
    overflow: hidden;
    @media (min-width: ${desktopBreakpoint}) {
      height: 262px;
      border-radius: 6px;
    }
  }
`

const HeaderWrapper = styled.div`
  padding: 0 ${mobileVW(50)};
`

const TextWrapper = styled.div`
  text-align: left;
`

const SlideTitle = styled.h4`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  color: ${colors.brownDark};
  margin: ${mobileVW(14)} auto 0;
  text-transform: lowercase;
  font-weight: normal;

  p {
    display: inline-block;
  }
  span {
    color: ${colors.brownLight};
    font-size: ${mobileVW(26)};
    line-height: ${mobileVW(31)};
    @media (min-width: ${desktopBreakpoint}) {
      line-height: 31px;
      font-size: 26px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    line-height: 39px;
    font-size: 36px;
    margin: 15px auto 0;
  }
`

const SlideJobTitle = styled.h4`
  font-family: ${font.larsseit};
  font-weight: 400;
  margin: 0;
  font-size: ${mobileVW(15)};
  line-height: ${mobileVW(18)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 20px;
    line-height: 24.5px;
  }
`

const SlideSubtitle = styled.h5`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  font-weight: normal;
  margin: ${mobileVW(10)} auto 0;
  color: ${colors.black};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin: 10px auto 0;
  }
`

const ButtonWrapper = styled.div`
  a {
    font-size: ${mobileVW(18)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
    }
  }
  margin: ${mobileVW(35)} auto 0;
  width: max-content;

  @media (max-width: ${desktopBreakpoint}) {
    .button_wrapper {
      padding: ${mobileVW(13)} ${mobileVW(24)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    grid-area: text;
    display: flex;
    margin: 40px auto 0;
    align-items: center;
    justify-content: flex-start;
  }
`

const StandardSwiper = ({ content }) => {
  const {
    title,
    description,
    slideCategories,
    images,
    button,
    multiBlockPosition,
    useCampaignQuote,
  } = content

  const isMobile = useIsMobile()

  const swiperParams = isMobile
    ? {
        slidesPerView: 1.4,
        spaceBetween: 16,
        // navigation: true,
      }
    : {
        initialSlide: 0,
        spaceBetween: 40,
        slidesPerView: 4,
        centerInsufficientSlides: true,
        // navigation: true,
      }

  return (
    <BoxedContainer>
      <Wrapper
        blockPosition={multiBlockPosition}
        showNavigation={slideCategories?.length <= 4 || images?.length < 4}
        id={`${title
          .toLowerCase()
          .split(' ')
          .join('_')}`}
      >
        <HeaderWrapper>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
          {button && (
            <ButtonWrapper>
              <Button
                ctaType={'primary'}
                slug={button.link ? button.link[0].slug : button.externalLink}
                text={button.title}
                external={button.externalLink}
              />
            </ButtonWrapper>
          )}
        </HeaderWrapper>
        <Swiper {...swiperParams}>
          {images?.map((image, i) => (
            <SwiperSlide key={i}>
              <Image fluid={image.fluid} alt={image.title} loading={'lazy'} />
            </SwiperSlide>
          ))}

          {slideCategories?.map((slide, i) => (
            <SwiperSlide key={i}>
              {slide.instagramUrl || (slide.slug && !slide.logo?.svg) ? (
                <SlideImage
                  href={slide.instagramUrl?.instagramUrl || slide.slug}
                >
                  <Image
                    fluid={
                      slide.image?.fluid ||
                      slide.instagramImage?.fluid ||
                      slide.thumbnail?.fluid ||
                      slide.logo?.fluid
                    }
                  />
                </SlideImage>
              ) : (
                <SlideImage>
                  {slide.logo?.svg ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: slide.logo.svg.content,
                      }}
                    />
                  ) : (
                    <Image
                      fluid={
                        slide.image?.fluid ||
                        slide.instagramImage?.fluid ||
                        slide.thumbnail?.fluid ||
                        slide.logo?.fluid
                      }
                    />
                  )}
                </SlideImage>
              )}

              <TextWrapper>
                {slide.title ? (
                  <SlideTitle>
                    {slide.title.includes('(') ? (
                      <div>
                        <p>{slide.title.split('(')[0]}</p>
                        <span>
                          {' '}
                          {slide.title
                            .split('(')[1]
                            .split(')')
                            .join('')}
                        </span>
                      </div>
                    ) : (
                      slide.title
                    )}
                  </SlideTitle>
                ) : null}
                {slide.jobFunction && (
                  <SlideJobTitle>{slide.jobFunction}</SlideJobTitle>
                )}
                {(slide.description ||
                  slide.seoDescription ||
                  slide.review) && (
                  <SlideSubtitle>
                    {useCampaignQuote
                      ? slide.campaignQuote
                      : slide.description?.description ||
                        slide.seoDescription ||
                        slide.review}
                  </SlideSubtitle>
                )}
              </TextWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    </BoxedContainer>
  )
}

export default StandardSwiper
