import React from 'react'
import styled from 'styled-components'

import IframeVideo from '../IframeVideo'

import { mobileVW, font, desktopBreakpoint } from '../../../styles'

const Container = styled.div`
  text-align: center;
  background: ${({ background }) => (background ? background[0] : 'none')};
  padding: ${({ page, background }) =>
    page == 'product'
      ? `${mobileVW(60)}`
      : !background
      ? '73px 0'
      : `${mobileVW(25)} ${mobileVW(25)} ${mobileVW(40)}`};
  @media (min-width: ${desktopBreakpoint}) {
    background: ${({ background }) => (background ? background[1] : 'none')};
    padding: ${({ background }) => (background ? ' 68px 198px 72px' : '0')};
    line-height: 32.2px;
  }
`

const StyledTitle = styled.h1`
  font-family: ${font.ben};
  font-size: ${mobileVW(58)};
  line-height: ${mobileVW(53.36)};
  margin-bottom: ${mobileVW(39)};
  padding: 0 ${mobileVW(10)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 100px;
    line-height: 96px;
    margin-bottom: 46px;
    padding: 0;
  }
`

const StyledText = styled.p`
  font-family: ${font.larsseit};
  font-weight: 400;
  font-size: ${mobileVW(20)};
  line-height: ${mobileVW(24.4)};
  margin-bottom: ${mobileVW(33)};
  padding: 0 ${mobileVW(10)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 56px;
    padding: 0 150px;
  }
`

const StyledTitlePdp = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  margin-bottom: ${mobileVW(16)};
  text-transform: lowercase;
  font-weight: normal;
  padding: 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 16px;
  }
`

const StyledTextPdp = styled.p`
  font-family: ${font.larsseit};
  font-weight: 400;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  margin-bottom: ${mobileVW(60)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 56px;
  }
`

const StyledVideo = styled.div`
  width: calc(100vw - 52px);
  margin: 0 auto;
  overflow: hidden;
  border-radius: ${mobileVW(6)};
  max-width: ${({ smaller }) => (smaller ? '670px' : 'none')};

  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 6px;
    width: 100%;
  }
`

const VideoWithTitleAndText = ({ contentBlock, page }) => {
  const { backgroundColors, title, text, videoLink, small } = contentBlock

  return (
    <Container background={backgroundColors} className='videoWithTextContainer'>
      {page == 'product' ? (
        <>
          {title && <StyledTitlePdp>{title}</StyledTitlePdp>}
          {text && <StyledTextPdp>{text}</StyledTextPdp>}
        </>
      ) : (
        <>
          {title && <StyledTitle>{title}</StyledTitle>}
          {text && <StyledText>{text}</StyledText>}
        </>
      )}
      <StyledVideo smaller={page == 'product' || small}>
        <IframeVideo videoSrcURL={videoLink} videoTitle={title} />
      </StyledVideo>
    </Container>
  )
}

export default VideoWithTitleAndText
