import React from 'react'

const IframeVideo = ({ videoSrcURL, videoTitle, className }) => (
  <div
    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
    className={className}
  >
    <iframe
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
      }}
      src={videoSrcURL}
      title={videoTitle}
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      frameBorder='0'
      webkitallowfullscreen='true'
      mozallowfullscreen='true'
      allowFullScreen
    />
  </div>
)
export default IframeVideo
