import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, font, mobileVW } from '../../../styles'

// Utils
import { getPathname } from '/src/utils'

// Hooks
import { useLayoutCtx, useDictionaryCtx } from '../../../hooks/context'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import RichText from '../RichText'

const Container = styled.section`
  padding-bottom: 35px;
  padding-top: 60px;

  @media (min-width: ${desktopBreakpoint}) {
    padding-bottom: 70px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const Heading = styled.h3`
  color: ${colors.brownDark};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  font-family: ${font.ben};
  text-transform: lowercase;
  font-weight: normal;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
  }
`

const Description = styled.div`
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  font-family: ${font.larsseit};
  margin: ${mobileVW(16)} ${mobileVW(28)} 0;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin: 16px auto 0;
    max-width: 570px;
  }
`

const ExpertsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${mobileVW(35)} ${mobileVW(16)};
  margin: ${mobileVW(35)} ${mobileVW(16)} ${mobileVW(67)};
  justify-content: center;

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px 40px;
    max-width: 866px;
    margin: 36px auto 0;
  }
`

const Expert = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  button {
    margin-top: auto;
    border: solid 2px ${colors.beigeUltraLight};
    width: 100%;
    font-family: ${font.ben};
    font-size: ${mobileVW(18)};
    line-height: ${mobileVW(22)};
    height: ${mobileVW(48)};
    border-radius: 100vw;
    text-transform: lowercase;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
      line-height: 22px;
      height: 50px;
      &:hover {
        background: ${colors.beigeUltraLight};
        color: ${colors.brownDark};
      }
    }
  }
`

const Name = styled.h2`
  font-family: ${font.ben};
  font-weight: normal;
  margin-top: ${mobileVW(9)};
  text-transform: lowercase;
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(22)};
  line-height: ${mobileVW(27)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 28px;
    margin-top: 9px;
    line-height: 31px;
  }
`

const JobFunction = styled.div`
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  font-family: ${font.larsseit};
  text-transform: uppercase;
  color: ${props => props.color};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${props => (props.large ? '3px' : '0')};
    font-size: ${props => (props.large ? '16px' : '14px')};
    line-height: 24px;
  }
`

const Subtitle = styled.div`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(18)};
  margin-top: ${mobileVW(10)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 6px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  width: ${mobileVW(164)};
  height: ${mobileVW(164)};

  &:hover {
    div {
      visibility: visible;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: 262px;
    height: 262px;
    // margin: auto;
  }
`

const FlipSide = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextWrapper = styled.div`
  & + .expert-footer {
    padding-top: ${mobileVW(10)};

    @media (min-width: ${desktopBreakpoint}) {
      padding-top: 23px;
    }
  }
`

const ExpertFooter = styled.div`
  margin-top: auto;
`

const GridSection = ({ content }) => {
  const { node_locale } = useLayoutCtx()
  const { academyButtonLabel } = useDictionaryCtx()

  const {
    title,
    gridSubtitle,
    people,
    includeCardSubtitle,
    includeButton,
  } = content

  return (
    <Container className='gridSectionContainer'>
      <Heading>{title}</Heading>
      <Description>
        <RichText json={gridSubtitle.json} />
      </Description>
      <ExpertsWrapper>
        {people.map(
          (
            { slug, jobFunction, image, name, shortSubtitle, hoverImage },
            i,
          ) => (
            <Expert key={i} to={getPathname(node_locale, slug)}>
              <ImageWrapper>
                <StyledImage fluid={image.fluid} alt={image.title} />

                {hoverImage ? (
                  <FlipSide>
                    <StyledImage
                      fluid={hoverImage.fluid}
                      alt={hoverImage.title}
                    />
                  </FlipSide>
                ) : null}
              </ImageWrapper>

              <TextWrapper>
                <Name>{name}</Name>

                <JobFunction
                  large={includeCardSubtitle}
                  color={
                    includeCardSubtitle
                      ? `${colors.brownLight}`
                      : `${colors.brownDarkest}`
                  }
                >
                  {jobFunction}
                </JobFunction>

                {includeCardSubtitle && shortSubtitle && (
                  <Subtitle>{shortSubtitle}</Subtitle>
                )}
              </TextWrapper>

              {includeButton && (
                <ExpertFooter className='expert-footer'>
                  <button>{academyButtonLabel}</button>
                </ExpertFooter>
              )}
            </Expert>
          ),
        )}
      </ExpertsWrapper>
    </Container>
  )
}

export default GridSection
