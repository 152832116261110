import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Button from '../uiComponents/button'
import RichText from '../RichText'
import NewsletterBlock from './newsletterBlock'
import { font, desktopBreakpoint, colors, mobileVW } from '../../../styles'

const Container = styled.div`
  padding: 40px 0;
  margin: auto;
  @media (min-width: ${desktopBreakpoint}) {
    width: 800px;
  }
`

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-flow: ${props => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: 36px;
  text-align: center;
  margin-bottom: 36px;
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 83px;
    font-size: 48px;
  }
`
const Text = styled.div`
  padding: ${({ noButton }) =>
    noButton ? '36px 24px 0px 44px' : '36px 24px 32px 44px'};
  font-family: ${font.larsseit};
  font-size: 16px;
  line-height: 22px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;

  h3 {
    font-family: ${font.ben};
    color: ${colors.brownLight};
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 12px;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 36px;
    }
  }

  .embedded-newsletter-block {
    margin-top: ${mobileVW(32)};
    margin-left: -20px;
    align-self: center;

    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 47px;
      margin-left: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 45px;
    font-size: 20px;
    line-height: 29px;
    display: block;
  }
`

const StyledImage = styled(Image)`
  width: 211px;
  height: 255px;
  margin: auto;
  border-radius: 25px;
  @media (min-width: ${desktopBreakpoint}) {
    width: 370px;
    height: 450px;
    margin: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${desktopBreakpoint}) {
    width: 392px;
  }

  .button_wrapper {
    border: solid 1px black;
    min-width: 200px;
    margin: auto;
    @media (min-width: ${desktopBreakpoint}) {
      margin: 0;
    }
  }

  .homepage_newsletter_wrapper {
    padding: 0;
    display: block;
  }
`

const StyledButton = styled(Button)`
  &:hover {
    background: ${colors.beigeUltraLight};
    color: ${colors.brownDark};
    border-color: ${colors.beigeUltraLight};
  }
`

const ImageAndTextSection = ({ content }) => {
  const {
    title,
    longText,
    buttonTarget,
    buttonText,
    image,
    addNewsletterField,
    imagePlacement,
  } = content

  return (
    <Container className='imageAndTextSectionContainer'>
      <Title>{title}</Title>
      <Flexbox reverse={!imagePlacement}>
        <StyledImage fluid={image.fluid} alt={image.title} />
        <Wrapper>
          <Text noButton={!buttonTarget}>
            <RichText json={longText.json} />
          </Text>
          {buttonTarget && (
            <StyledButton
              slug={buttonTarget.slug || buttonTarget.internalLink}
              ctaType={'secondary'}
              text={buttonText}
            />
          )}
        </Wrapper>
      </Flexbox>
    </Container>
  )
}

export default ImageAndTextSection
