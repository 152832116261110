import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'

// install Swiper components
SwiperCore.use([Pagination])
SwiperCore.use([Navigation])

const Wrapper = styled.div`
  text-align: center;
  padding: ${mobileVW(80)} 0 ${mobileVW(100)};
  background-color: ${props => props.background};

  .swiper-pagination {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-container {
    height: ${mobileVW(300)};
    @media (min-width: ${desktopBreakpoint}) {
      height: auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    width: ${mobileVW(8)};
    height: ${mobileVW(8)};
    background: white;
    border: none;
    opacity: 1;

    &:not(:last-of-type) {
      margin-right: ${mobileVW(10)};

      @media (min-width: ${desktopBreakpoint}) {
        margin-right: 10px;
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      width: 8px;
      height: 8px;
    }
  }

  .swiper-pagination-bullet-active {
    width: ${mobileVW(12)};
    height: ${mobileVW(12)};
    background: transparent;
    border: solid ${mobileVW(1)} white;
    @media (min-width: ${desktopBreakpoint}) {
      width: 12px;
      height: 12px;
      border: solid 1px white;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 63px 0 76px;
    .swiper-button-next {
      display: flex;
      background: white;
      height: 45px;
      width: 45px;
      right: 157px;
      opacity: 1;
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12H18' stroke='%23DDC8B6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 9L18 12L14 15' stroke='%23DDC8B6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(48)};
  line-height: ${mobileVW(52)};
  max-width: ${mobileVW(300)};
  text-align: center;
  margin: auto;
  color: white;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    max-width: none;
  }
`

const StyledImage = styled.div`
  margin: auto;
  width: ${mobileVW(92)};
  margin-top: ${mobileVW(40)};
  @media (min-width: ${desktopBreakpoint}) {
    width: 96px;
    margin-top: 50px;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  padding: ${mobileVW(35)} ${mobileVW(32)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 90px;
  }
`

const SlideSubtitle = styled.h5`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(20)};
  line-height: ${mobileVW(32)};
  font-weight: normal;
  margin: 0 auto;
  color: ${colors.black};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 24px;
    margin: 80px auto 0;
    max-width: 570px;
    line-height: 32px;
  }
`

import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'

const BulletsSwiper = ({ content }) => {
  const { title, slideCategories } = content

  return (
    <>
      <Wrapper background={content.backgroundColor}>
        <Title>{title}</Title>
        <Swiper
          slidesPerView={1}
          centeredSlides
          loop
          pagination={{ clickable: true }}
          navigation
        >
          {slideCategories
            ? slideCategories.map((category, i) => (
                <SwiperSlide key={i}>
                  <TextWrapper>
                    <SlideSubtitle>{category.review}</SlideSubtitle>
                    <StyledImage>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: category.logo.svg.content,
                        }}
                      />
                    </StyledImage>
                  </TextWrapper>
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </Wrapper>
    </>
  )
}

export default BulletsSwiper
