import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import Image from 'gatsby-image'
import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'

SwiperCore.use([Navigation])

const MediaSwiper = ({ content }) => {
  const { images = [], title } = content

  return (
    <Container>
      <Title>{title}</Title>
      <Swiper
        initialSlide={0}
        spaceBetween={16}
        slidesPerView={'auto'}
        watchSlidesVisibility={true}
        autoHeight={true}
        breakpoints={{
          1024: {
            spaceBetween: 40,
          },
        }}
      >
        {images.map(({ fluid, description }, i) => (
          <SwiperSlide key={i}>
            <Slide>
              <SlideImage fluid={fluid} />
              <SlideCaption>{description}</SlideCaption>
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

const Container = styled.section`
  margin-bottom: ${mobileVW(50)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 110px;
  }

  .swiper-container {
    padding: 0 20px;
    width: auto;
  }

  .swiper-wrapper {
    width: fit-content;
    margin: 0 auto;
  }

  .swiper-slide {
    max-width: ${mobileVW(253)};

    &:last-child {
      margin-right: 0 !important;
    }

    @media (min-width: ${desktopBreakpoint}) {
      max-width: 262px;
    }
  }
`

const Title = styled.h2`
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  text-transform: lowercase;
  font-family: ${font.ben};
  text-align: center;
  margin-bottom: ${mobileVW(50)};
  padding: 0 ${mobileVW(20)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 80px;
    padding: 0;
  }
`

const Slide = styled.div`
  border-radius: ${mobileVW(6)};

  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 6px;
  }
`

const SlideImage = styled(Image)`
  height: ${mobileVW(270)};
  width: ${mobileVW(253)};
  object-fit: cover;
  object-position: center;
  border-radius: ${mobileVW(6)};

  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 6px;
    height: 262px;
    width: 262px;
  }
`

const SlideCaption = styled.h4`
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  font-family: ${font.ben};
  text-transform: lowercase;
  color: ${colors.brownDark};
  margin-top: ${mobileVW(14)};
  margin-bottom: 0;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 36px;
    line-height: 39px;
    margin-top: 15px;
  }
`

export default MediaSwiper
