import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

// Utils
import { getPathname } from '../../../utils'

// Hooks
import useLayoutCtx from '../../../hooks/context/useLayoutCtx'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import BoxedContainer from '../boxedContainer'

const Wrapper = styled.div`
  text-align: center;
  padding: ${mobileVW(60)} 0 0 0;
  background-color: ${props =>
    props.background ? `${props.background}` : 'inherit'};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 80px 0 48px;
    .swiper-slide {
      max-width: 385px;
    }
    .swiper-wrapper {
      justify-content: center;
    }
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  text-align: center;
  margin: 0 auto ${mobileVW(60)};
  color: ${colors.brownDarkest};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    margin: 0 auto 78px;
  }
`

const Circle = styled.div`
  height: ${mobileVW(100)};
  width: ${mobileVW(100)};
  margin: auto;
  border-radius: 50%;
  background-color: ${props => props.colors[0]};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: ${props => (props.fullBackground ? '100%' : '40%')};
  }

  @media (min-width: ${desktopBreakpoint}) {
    background-color: ${props => props.colors[1]};
    height: 100px;
    width: 100px;
  }
`

const SlideTitle = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(18.9)};
  color: ${colors.brownDarkest};
  margin: ${mobileVW(25)} auto 0;
  max-width: ${mobileVW(219)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 24px;
    line-height: 18.9px;
    padding-bottom: 5px;
    margin: 25px auto 0;
    max-width: 260px;
  }
`

const SlideSubtitle = styled.div`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  color: #777777;
  margin: ${mobileVW(10)} auto 0;
  max-width: ${mobileVW(219)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin: 10px auto 0;
    max-width: 220px;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
`

const StyledNonLink = styled.div`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
`

import {
  mobileVW,
  newDesktopVW,
  font,
  colors,
  desktopBreakpoint,
} from '../../../styles'

const CategorySwiper = ({
  content,
  slidesMobile,
  slidesDesktop,
  centerSlides,
}) => {
  const { node_locale } = useLayoutCtx()

  const {
    title,
    slideCategories,
    backgroundColors,
    icons,
    fullBackgroundImage,
    sectionBackgroundColor,
    customTitles,
    customSubtitles,
  } = content

  const visibleSlidesDesktop = slidesDesktop
    ? slidesDesktop
    : slideCategories
    ? slideCategories.length <= 3
      ? 4
      : 3
    : 4

  return (
    <>
      <BoxedContainer className='circleSwiperContainer'>
        <Wrapper
          background={sectionBackgroundColor}
          className='circleSwiper'
          id={`${title
            .toLowerCase()
            .split(' ')
            .join('_')}`}
        >
          <Title>{title}</Title>
          <Swiper
            slidesPerView={slidesMobile ? slidesMobile : 1.6}
            centeredSlides
            initialSlide={1}
            updateOnWindowResize
            breakpoints={{
              1024: {
                initialSlide: centerSlides ? 1 : 0,
                slidesPerView: visibleSlidesDesktop,
                loop: false,
                centeredSlides: centerSlides ? true : false,
              },
            }}
          >
            {slideCategories
              ? slideCategories.map((category, i) => (
                  <SwiperSlide key={i}>
                    <StyledLink
                      key={category.title}
                      to={
                        category.link
                          ? getPathname(node_locale, category.link[0]?.slug)
                          : getPathname(node_locale, category.internalLink)
                      }
                    >
                      <Circle
                        colors={backgroundColors}
                        fullBackground={fullBackgroundImage}
                      >
                        <Image fluid={icons[i].fluid} />
                      </Circle>
                      <SlideTitle>
                        {customTitles ? customTitles[i] : category.title}
                      </SlideTitle>
                      {customSubtitles && (
                        <SlideSubtitle> {customSubtitles[i]}</SlideSubtitle>
                      )}
                    </StyledLink>
                  </SwiperSlide>
                ))
              : icons.map((icon, i) => (
                  <SwiperSlide key={i}>
                    <StyledNonLink>
                      <Circle
                        colors={backgroundColors}
                        fullBackground={fullBackgroundImage}
                      >
                        <Image fluid={icon.fluid} />
                      </Circle>
                      <SlideTitle>
                        {customTitles ? customTitles[i] : icon.description}
                      </SlideTitle>
                      {customSubtitles && (
                        <SlideSubtitle> {customSubtitles[i]}</SlideSubtitle>
                      )}
                    </StyledNonLink>
                  </SwiperSlide>
                ))}
          </Swiper>
        </Wrapper>
      </BoxedContainer>
    </>
  )
}

export default CategorySwiper
