import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import {
  mobileVW,
  font,
  desktopBreakpoint,
  newDesktopVW,
} from '../../../styles'

const Container = styled.div`
  background: ${props => props.background[0]};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: ${mobileVW(26)};
  padding: ${props =>
    props.blockPosition === 'top'
      ? `${mobileVW(42)} ${mobileVW(20)} ${mobileVW(10)} ${mobileVW(20)}`
      : props.blockPosition === 'bottom'
      ? `${mobileVW(10)} ${mobileVW(20)} ${mobileVW(42)} ${mobileVW(20)}`
      : props.blockPosition === 'middle'
      ? `${mobileVW(10)} ${mobileVW(20)}`
      : `${mobileVW(42)} ${mobileVW(20)}`}; // 'standalone'

  @media (min-width: ${desktopBreakpoint}) {
    background: ${props => props.background[1]};
    grid-row-gap: ${newDesktopVW(83)};
    padding: ${props =>
      props.blockPosition === 'top'
        ? `85px 120px 36px 120px`
        : props.blockPosition === 'bottom'
        ? `36px 120px 85px 120px`
        : props.blockPosition === 'middle'
        ? `36px 120px`
        : `85px 120px`}; // 'standalone'
  }
`

const QuoteTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  order: ${props => props.order};
  ${props =>
    props.position === 'left'
      ? `padding-right: ${mobileVW(10)}`
      : `padding-left: ${mobileVW(10)}`};

  @media (min-width: ${desktopBreakpoint}) {
    ${props =>
      props.position === 'left' ? `padding-right: 28px` : `padding-left: 28px`};
  }
`

const QuoteCommentWrapper = styled.h3`
  font-family: ${font.ben};
  font-size: ${mobileVW(20)};
  line-height: ${mobileVW(18.4)};
  margin-bottom: ${mobileVW(9)};
  text-transform: lowercase;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 45px;
    line-height: 42px;
    margin-bottom: 10px;
  }
`
const QuoteAuthorWrapper = styled.p`
  font-family: ${font.larsseit};
  font-weight: 400;
  margin: 0;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(12.88)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 32px;
    line-height: 32px;
  }
`

const QuoteImageWrapper = styled.div`
  display: flex;
  justify-content: ${props =>
    props.position === 'left' ? 'flex-start' : 'flex-end'};
  order: ${props => props.order};
  ${props =>
    props.position === 'left'
      ? `padding-left: ${mobileVW(8)}`
      : `padding-right: ${mobileVW(8)}`};
  @media (min-width: ${desktopBreakpoint}) {
    ${props =>
      props.position === 'left' ? `padding-left: 60px` : `padding-right: 60px`};
  }
`

const StyledImage = styled(Image)`
  width: ${mobileVW(141)};
  height: ${mobileVW(170)};
  border-radius: ${mobileVW(5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: 452px;
    height: 542px;
    border-radius: 5px;
  }
`

const TwoQuotes = ({ contentBlock }) => {
  const {
    backgroundColors,
    firstQuoteText,
    firstQuoteAuthor,
    firstQuoteImage,
    secondQuoteText,
    secondQuoteAuthor,
    secondQuoteImage,
    imageOrder,
    multiBlockPosition,
  } = contentBlock
  const firstImagePosition = imageOrder ? 'right' : 'left'
  const firstTextPosition = imageOrder ? 'left' : 'right'
  const secondImagePosition = imageOrder ? 'left' : 'right'
  const secondTextPosition = imageOrder ? 'right' : 'left'

  const firstTextOrder = imageOrder ? '0' : '1'
  const firstImageOrder = imageOrder ? '1' : '0'
  const secondImageOrder = imageOrder ? '2' : '3'
  const secondTextOrder = imageOrder ? '3' : '2'

  return (
    <Container blockPosition={multiBlockPosition} background={backgroundColors}>
      <QuoteTextContainer position={firstTextPosition} order={firstTextOrder}>
        <QuoteCommentWrapper>{firstQuoteText}</QuoteCommentWrapper>
        <QuoteAuthorWrapper>{firstQuoteAuthor}</QuoteAuthorWrapper>
      </QuoteTextContainer>

      <QuoteImageWrapper position={firstImagePosition} order={firstImageOrder}>
        <StyledImage
          fluid={firstQuoteImage.fluid}
          alt={firstQuoteImage.title}
        />
      </QuoteImageWrapper>

      <QuoteImageWrapper
        position={secondImagePosition}
        order={secondImageOrder}
      >
        <StyledImage
          fluid={secondQuoteImage.fluid}
          alt={secondQuoteImage.title}
        />
      </QuoteImageWrapper>

      <QuoteTextContainer position={secondTextPosition} order={secondTextOrder}>
        <QuoteCommentWrapper>{secondQuoteText}</QuoteCommentWrapper>
        <QuoteAuthorWrapper>{secondQuoteAuthor}</QuoteAuthorWrapper>
      </QuoteTextContainer>
    </Container>
  )
}

export default TwoQuotes
