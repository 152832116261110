import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

// Utils
import { getPathname } from '/src/utils'

// Hooks
import useLayoutCtx from '../../../hooks/context/useLayoutCtx'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import Button from '../uiComponents/button'
import CategoryFrame from '../categoryFrame'
import BoxedContainer from '../boxedContainer'

const Wrapper = styled.div`
  text-align: center;
  padding: ${mobileVW(90)} 0;
  background: ${props =>
    props.gradient
      ? `linear-gradient(to top, ${props.colors[0]} 0%, ${props.colors[1]} 100%);`
      : `${props.colors[0]}`};

  .swiper-container {
    margin-left: ${mobileVW(16)};
  }

  .swiper-slide {
    border-radius: ${mobileVW(6)};
    height: ${props =>
      props.maxWidth ? `${mobileVW(props.maxWidth)}` : `${mobileVW(164)}`};
    max-width: ${props =>
      props.maxWidth ? `${mobileVW(props.maxWidth)}` : `${mobileVW(164)}`};
    overflow: hidden;
    @media (min-width: ${desktopBreakpoint}) {
      height: auto;
      max-width: none;
    }
  }

  h2 {
    color: ${props => (props.gradient ? 'white' : `${colors.brownDarkest}`)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 72px 0 32px;
    background: ${props =>
      props.gradient
        ? `linear-gradient(to left, ${props.colors[0]} 0%, ${props.colors[1]} 100%);`
        : `${props.colors[0]}`};
    width: 100%;
    display: block;
  }
`

const MobileWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`
const DesktopWrapper = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }
`

const Left = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${props => (props.small ? '0' : '165px')};
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
  }
`

const Right = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    max-height: 650px;
    overflow-y: scroll;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    padding-left: 20px;
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  text-transform: lowercase;
  margin: ${props =>
    props.maxWidth
      ? `0 ${mobileVW(16)} ${mobileVW(32)}`
      : `0 ${mobileVW(55)} ${mobileVW(45)}`};
  font-size: ${props =>
    props.maxWidth ? `${mobileVW(36)}` : `${mobileVW(48)}`};
  line-height: ${props =>
    props.maxWidth ? `${mobileVW(39)}` : `${mobileVW(52)}`};
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    grid-area: text;
    text-align: left;
    letter-spacing: normal;
    max-width: 265px;
    font-size: 48px;
    line-height: 52px;
    margin: 0 auto 40px 157px;
  }
`

const SlideImage = styled(Link)`
  position: relative;
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (min-width: ${desktopBreakpoint}) {
    height: 262px;
    width: 262px;
    border-radius: 6px;
    overflow: hidden;
  }
`

const SlideTitle = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(32)};
  line-height: ${mobileVW(42)};
  color: ${props => props.color};
  position: absolute;
  text-transform: lowercase;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background: rgba(101, 71, 63, 0.1);
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 32px;
    line-height: 42px;
  }
`

const ButtonWrapper = styled.div`
  margin: ${mobileVW(40)} auto 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 auto 0 157px;
    grid-area: text;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

const Description = styled.p`
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(20)};
  margin-bottom: ${mobileVW(32)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 26px;
    margin-bottom: 32px;
  }
`

const BigGridWrapper = styled.div`
  display: flex;
`

const SmallGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    padding: 0 76px;
  }

  h2 {
    font-size: 40px;
    margin: 0 auto 24px;
  }

  a {
    margin-top: 34px;
  }
  ${ButtonWrapper} {
    margin: 32px auto 0;
  }
`

const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin-top: 0;
    width: 153px;
    height: 153px;
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
`

import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'

const CategorySwiper = ({ content }) => {
  const { node_locale } = useLayoutCtx()

  const {
    backgroundColors = [],
    title,
    slideCategories,
    button,
    description,
    slidesMobile,
    small,
  } = content

  const params = {
    spaceBetween: 16,
    slidesPerView: slidesMobile ? slidesMobile : 2,
  }

  return (
    <>
      <Wrapper
        colors={backgroundColors}
        gradient={backgroundColors[1] ? true : false}
        small={small}
        className='category_swiper'
        maxWidth={slidesMobile && slidesMobile == '1.6' ? '208' : null}
        id={`${title
          .toLowerCase()
          .split(' ')
          .join('_')}`}
      >
        <MobileWrapper>
          <Title
            maxWidth={slidesMobile && slidesMobile == '1.6' ? '208' : null}
          >
            {title}
          </Title>
          {description ? <Description>{description}</Description> : null}
          <Swiper {...params}>
            {slideCategories
              ? slideCategories.map((category, i) => (
                  <SwiperSlide key={i}>
                    <SlideImage to={getPathname(node_locale, category.slug)}>
                      <Image
                        fluid={
                          category.thumbnail?.fluid || category.heroImage?.fluid
                        }
                      />
                    </SlideImage>
                    <SlideTitle
                      color={
                        slidesMobile && slidesMobile == '1.6'
                          ? `${colors.beigeUltraLight}`
                          : 'white'
                      }
                    >
                      {category.title}
                    </SlideTitle>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
          <ButtonWrapper>
            <Button
              ctaType={backgroundColors[1] ? 'secondary' : 'primary'}
              slug={button.link ? button.link[0]?.slug : button.internalLink}
              text={button.title}
            />
          </ButtonWrapper>
        </MobileWrapper>
        <DesktopWrapper>
          <BoxedContainer>
            {small ? (
              <SmallGridWrapper>
                <Title>{title}</Title>
                {description ? <Description>{description}</Description> : null}
                <SlideWrapper>
                  {slideCategories
                    ? slideCategories.map((category, i) => (
                        <SlideImage
                          key={i}
                          to={getPathname(node_locale, category.slug)}
                        >
                          <Image
                            fluid={
                              category.thumbnail?.fluid ||
                              category.heroImage?.fluid
                            }
                          />
                          <SlideTitle
                            color={
                              slidesMobile && slidesMobile == '1.6'
                                ? `${colors.beigeUltraLight}`
                                : 'white'
                            }
                          >
                            {category.title}
                          </SlideTitle>
                        </SlideImage>
                      ))
                    : null}
                </SlideWrapper>
                <ButtonWrapper>
                  <Button
                    ctaType={backgroundColors[1] ? 'secondary' : 'primary'}
                    slug={
                      button.link ? button.link[0]?.slug : button.internalLink
                    }
                    text={content.button.title}
                  />
                </ButtonWrapper>
              </SmallGridWrapper>
            ) : (
              <BigGridWrapper>
                <Left small={slideCategories && slideCategories.length < 3}>
                  <Title>{title}</Title>
                  <ButtonWrapper>
                    <Button
                      ctaType={backgroundColors[1] ? 'secondary' : 'primary'}
                      slug={
                        button.link ? button.link[0]?.slug : button.internalLink
                      }
                      text={content.button.title}
                    />
                  </ButtonWrapper>
                </Left>
                <Right>
                  {slideCategories
                    ? slideCategories.map((category, i) => (
                        <SlideImage
                          key={i}
                          to={getPathname(node_locale, category.slug)}
                        >
                          <CategoryFrame>
                            <Image
                              fluid={
                                category.thumbnail?.fluid ||
                                category.heroImage?.fluid
                              }
                            />
                            <SlideTitle
                              color={
                                slidesMobile && slidesMobile == '1.6'
                                  ? `${colors.beigeUltraLight}`
                                  : 'white'
                              }
                            >
                              <span>{category.title}</span>
                            </SlideTitle>
                          </CategoryFrame>
                        </SlideImage>
                      ))
                    : null}
                </Right>
              </BigGridWrapper>
            )}
          </BoxedContainer>
        </DesktopWrapper>
      </Wrapper>
    </>
  )
}

export default CategorySwiper
